import { render, staticRenderFns } from "./DetailsCustomFlex.vue?vue&type=template&id=74f13d1f&"
import script from "./DetailsCustomFlex.vue?vue&type=script&lang=js&"
export * from "./DetailsCustomFlex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports