<!--
  (1) Update DOM to easily support flex
  (2) Add optional CTA render
  (3) Optional list block render
-->
<template>
  <section
    class="details-section page-section"
    :class="content.classes"
  >
    <div class="container">
      <TitleBlock :content="content" />
      <div
        class="details-content grid"
        :class="[
          { 'is-carousel-on-mobile': content.carousel },
          `columns-${content.details.length}`
        ]"
      >
        <div
          v-for="(detail, index) of content.details"
          :id="detail.id"
          :key="index"
          class="detail"
          :class="`index-${index}`"
        >
          <MediaBlock :content="detail" />
          <!-- (1) -->
          <div class="detail-content detail-wrapper-content">
            <h4
              v-if="detail.title"
              class="detail-title"
              v-html="detail.title"
            />
            <p
              v-if="detail.text"
              class="detail-text"
              v-html="detail.text"
            />
            <!-- (3) -->
            <ListBlock :content="detail" />
          </div>
        </div>
      </div>
      <!-- (2) -->
      <CTABlock :content="content" />
    </div>
  </section>
</template>

<script>
import {
  MediaBlock,
  TitleBlock,
  CTABlock,
  ListBlock,
} from './shared'

export default {
  components: {
    TitleBlock,
    MediaBlock,
    CTABlock,
    ListBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (this.content.carousel) this.initializeCarousel()
  },
  methods: {
    initializeCarousel() {
      const { content } = this
      $('.details-content.is-carousel-on-mobile').each(function () {
        const slider = $(this)
        if ($(this).replaceWithSVG) {
          slider.bind('DOMNodeInserted', () => {
            slider.find('[src$=".svg"]').each(function () {
              $(this).replaceWithSVG()
            })
          })
        }
        slider.slick({
          mobileFirst: true,
          responsive: content.responsive_settings || [
            {
              breakpoint: 0,
              settings: {
                arrows: false,
                dots: true,
                slidesToScroll: 1,
                slidesToShow: 1,
              },
            },
          ],
        })
      })
    },
  },
}
</script>
