<template>
  <header
    class="site-header"
    :class="content.classes"
  >
    <div class="container">
      <a
        v-scroll-to="{
          el: content.logo_link,
          offset: content.offset || -80,
          duration: 500,
        }"
        :href="content.logo_link"
      >
        <img
          class="header-logo"
          :src="content.logo_image"
          alt="header-logo-img"
        >
      </a>
      <p
        v-if="content.text"
        class="header-text"
        v-html="content.text"
      />
      <CTABlock :content="content" />
    </div>
  </header>
</template>

<script>
import { CTABlock } from './shared'

export default {
  components: {
    CTABlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
