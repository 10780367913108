<template>
  <section
    class="details-section page-section"
    :class="content.classes"
  >
    <div class="container">
      <TitleBlock :content="content" />
      <div
        class="details-content grid"
        :class="[
          { 'is-carousel-on-mobile': content.carousel },
          `columns-${content.details.length}`
        ]"
      >
        <div
          v-for="(detail, index) of content.details"
          :id="detail.id"
          :key="index"
          class="detail"
          :class="`index-${index}`"
        >
          <div class="detail-wrapper">
            <MediaBlock :content="detail" />
            <h4
              v-if="detail.title"
              class="detail-title"
              v-html="detail.title"
            />
            <p
              v-if="detail.text"
              class="detail-text"
              v-html="detail.text"
            />
            <ListBlock
              v-if="detail.list_items"
              :content="detail"
            />
          </div>
        </div>
      </div>
      <CTABlock
        v-if="content.cta_text || content.cta_link"
        :content="content"
      />
    </div>
  </section>
</template>

<script>
import {
  MediaBlock,
  TitleBlock,
  ListBlock,
  CTABlock,
} from './shared'

export default {
  components: {
    TitleBlock,
    MediaBlock,
    ListBlock,
    CTABlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (this.content.carousel) this.initializeCarousel()
  },
  methods: {
    initializeCarousel() {
      const { content } = this
      $('.details-content.is-carousel-on-mobile').each(function () {
        const slider = $(this)
        if ($(this).replaceWithSVG) {
          slider.bind('DOMNodeInserted', () => {
            slider.find('[src$=".svg"]').each(function () {
              $(this).replaceWithSVG()
            })
          })
        }
        slider.slick({
          mobileFirst: true,
          responsive: content.responsive_settings || [
            {
              breakpoint: 0,
              settings: {
                arrows: false,
                dots: true,
                slidesToScroll: 1,
                slidesToShow: 1,
              },
            },
          ],
        })
      })
    },
  },
}
</script>
