<template>
  <div class="linkbar">
    <a
      v-scroll-to="{
        el: content.cta_link,
        offset: content.offset || -80,
        duration: 500,
      }"
      class="linkbar-text"
      :href="content.cta_link"
      :data-trackable="content.trackable || 'quiz'"
      :data-interaction="content.interaction || 'linkbar-click'"
      v-html="content.text"
    />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
