<template>
  <footer class="site-footer">
    <div class="container">
      <div
        v-if="content.expanded"
        class="footer-brand"
      >
        <img
          class="footer-logo"
          :src="content.logo_image"
          alt="Footer Logo"
        >
      </div>
      <div
        v-if="content.disclaimer && !hasMonthlyPricing"
        class="footer-disclaimer"
      >
        <p class="footer-disclaimer-text" v-html="content.disclaimer" />
      </div>
      <div class="footer-meta">
        <p class="footer-copyright">
          &copy; {{ currentYear }}
          <span v-html="content.copyright" />
        </p>
        <ul class="footer-links">
          <li
            v-for="(link, index) in content.links"
            :key="index"
          >
            <a
              target="_blank"
              :href="link.url"
            >{{ link.text }}</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      content: this.$quizData.footer,
      hasMonthlyPricing: this.$quizData.has_monthly_pricing,
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>
