import Vue from 'vue'
import Vuex from 'vuex'
import NorthForm from '@sheridan-media/north-form'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import store from '../store/index'
import App from '../App.vue'

const VueScrollTo = require('vue-scrollto')

Vue.use(Vuex)
Vue.use(NorthForm, { store })
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueScrollTo)
Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('north-form')
  if (element) {
    const quizData = JSON.parse(element.dataset.quiz)
    Vue.prototype.$quizData = quizData
    // eslint-disable-next-line no-unused-vars
    const vm = new Vue({
      el: '#north-form',
      store,
      render: h => h(App),
    })
  }
})
