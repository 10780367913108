<template>
  <MediaBlock
    :class="{ 'animate-media-rotate': !content.no_animation }"
    :content="currentContent"
  />
</template>

<script>
import { MediaBlock } from './shared'

export default {
  components: {
    MediaBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentIdx: 0,
    }
  },
  computed: {
    currentContent() {
      return { ...this.content.images[this.currentIdx], figcaption: this.content.figcaption }
    },
  },
  mounted() {
    this.incrementCurrentIdx()
  },
  methods: {
    incrementCurrentIdx() {
      setInterval(() => {
        this.currentIdx = (this.currentIdx + 1) % this.content.images.length
      }, this.content.no_animation ? 3000 : 5000)
    },
  },
}
</script>

<style>
.animate-media-rotate img {
  animation: fade-in-out 5s linear infinite;
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
