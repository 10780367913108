<template>
  <blockquote
    v-if="content.quote"
    class="quote-block"
  >
    <figure
      v-if="ratingIcon || content.rating_icon_image"
      class="rating-icon"
    >
      <img
        :src="ratingIcon || content.rating_icon_image"
        :alt="content.rating_icon_alt || ''"
      >
    </figure>
    <p
      class="quote-body"
      v-html="content.quote"
    />
    <div class="quote-attribution-wrapper">
      <figure
        v-if="content.attribution_icon_image"
        class="attribution-icon"
      >
        <img
          :src="content.attribution_icon_image"
          :alt="content.attribution_icon_alt || ''"
        >
      </figure>
      <h6
        v-if="content.author"
        class="quote-author"
        v-html="content.author"
      />
      <small
        v-if="content.attribution"
        class="quote-attribution"
        v-html="content.attribution"
      />
    </div>
  </blockquote>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
    ratingIcon: {
      type: String,
      default: '',
    },
  },
}
</script>
