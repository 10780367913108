
<template>
  <section
    class="details-section page-section"
    :class="content.classes"
  >
    <div class="container">
      <div class="zipcode-search-wrapper">
        <TitleBlock :content="content" />
      </div>

      <div
        class="details-content grid"
        :class="[
          { 'is-carousel-on-mobile': content.carousel },
          `columns-${content.details.length}`
        ]"
      >
        <CustomProductDetails
          v-for="(itp, i) in content.details"
          :key="i"
          :content="itp"
        />
      </div>

      <CTABlock :content="content" />
    </div>
  </section>
</template>

<script>
import TitleBlock from './shared/TitleBlock.vue'
import CustomProductDetails from './CustomProductDetails.vue'
import CTABlock from './shared/CTABlock.vue'


export default {
  components: {
    TitleBlock,
    CTABlock,
    CustomProductDetails,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hasMonthlyPricing: this.$quizData.has_monthly_pricing,
    }
  },
}
</script>
