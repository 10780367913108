<template>
  <section class="sticky-quiz-top">
    <Linkbar
      v-if="quizData.linkbar"
      :content="quizData.linkbar"
    />
    <Header
      v-if="quizData.header"
      :content="quizData.header"
    />
  </section>
</template>

<script>
import Linkbar from '../components/Linkbar.vue'
import Header from '../components/Header.vue'

export default {
  components: {
    Linkbar,
    Header,
  },
  data() {
    return {
      quizData: this.$quizData,
    }
  },
}
</script>

<style scoped>
.sticky-quiz-top {
  position: sticky;
  top: 0;
  z-index: 99;
}
</style>
