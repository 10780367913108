<template>
  <section
    class="callout-section page-section"
    :class="content.classes"
  >
    <div class="container">
      <MediaRotator
        v-if="content.images"
        :content="content"
      />
      <MediaBlock
        v-else
        :content="content"
      />
      <div class="callout-content">
        <TitleBlock :content="content" />
        <ListBlock :content="content" />
        <CTABlock :content="content" />
      </div>
    </div>
  </section>
</template>

<script>
import {
  MediaBlock,
  TitleBlock,
  ListBlock,
  CTABlock,
} from './shared'
import MediaRotator from './MediaRotator.vue'

export default {
  components: {
    MediaBlock,
    TitleBlock,
    ListBlock,
    CTABlock,
    MediaRotator,
  },
  props: {
    content: {
      type: Object,
      default: () => {
      },
    },
  },
}
</script>
