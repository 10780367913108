<template>
  <section
    class="press-section page-section"
    :class="content.classes"
  >
    <h4
      v-if="content.title"
      class="press-title"
      v-html="content.title"
    />
    <div class="container">
      <div class="press-icons">
        <div
          v-for="(p, i) in content.brands"
          :key="i"
          class="press-wrapper"
        >
          <img
            class="press-icon"
            :src="p.image"
            :alt="p.alt || ''"
          >
          <p
            v-if="p.quote"
            class="press-quote"
            v-html="p.quote"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
