<template>
  <section
    class="accordion-section page-section"
    :class="content.classes"
  >
    <div class="container">
      <TitleBlock :content="content" />
      <div class="accordion">
        <template v-for="(slide, idx) in content.slides">
          <div
            :key="`header-${idx}`"
            class="accordion-header"
            @click="handleHeaderClick(idx)"
          >
            <div class="header-container">
              <h4
                class="accordion-title"
                v-html="slide.header"
              />
              <img
                class="down-arrow"
                :src="content.slide_icon_image"
                alt="Down Arrow"
              >
            </div>
          </div>
          <div
            :key="`content-${idx}`"
            class="accordion-content"
          >
            <div class="content-block">
              <h5
                v-if="slide.title"
                class="content-title"
                v-html="slide.title"
              />
              <p
                class="content-text"
                v-html="slide.text"
              />
            </div>
          </div>
        </template>
      </div>
      <CTABlock :content="content" />
    </div>
  </section>
</template>

<script>
import {
  TitleBlock,
  CTABlock,
} from './shared'
import { trackEvent } from '../helpers/tracking'

export default {
  components: {
    TitleBlock,
    CTABlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.initializeAccordion()
  },
  methods: {
    handleHeaderClick(idx) {
      trackEvent('cta-click', `faq-q${idx + 1}-cta`)
    },
    initializeAccordion() {
      $('.accordion-header').on('click', function () {
        const header = $(this)

        header
          .siblings('.active')
          .next()
          .slideToggle(400, function () {
            $(this).prev().toggleClass('active', $(this).is(':visible'))
          })

        if (header.hasClass('active')) {
          header
            .next()
            .slideToggle(400, function () {
              header.toggleClass('active', $(this).is(':visible'))
            })
        } else {
          header
            .toggleClass('active')
            .next()
            .slideToggle()
        }
      })
    },
  },
}
</script>
