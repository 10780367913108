<template>
  <section class="quiz-intro">
    <EmailSignupForm
      v-if="quizData.email_signup_form"
      :content="quizData.email_signup_form"
    />
    <Callout
      v-if="quizData.callout_hero"
      :content="quizData.callout_hero"
    />
    <Press
      v-if="quizData.press_carousel"
      :content="quizData.press_carousel"
    />
    <LongForm
      v-if="quizData.longform_default"
      :content="quizData.longform_default"
    />
    <DetailsCustomFlex
      v-if="quizData.testimonial_details"
      :content="quizData.testimonial_details"
    />
    <Details
      v-if="quizData.details_property"
      :content="quizData.details_property"
    />
    <Details
      v-if="quizData.hero_banner"
      :content="quizData.hero_banner"
    />
    <Reviews
      v-if="quizData.reviews_hero"
      :content="quizData.reviews_hero"
    />
    <WhyAbodu
      v-if="quizData.why_abodu"
      :content="quizData.why_abodu"
    />
    <LongForm
      v-if="quizData.longform"
      :content="quizData.longform"
    />
    <DetailsProducts
      v-if="quizData.product_cards"
      :content="quizData.product_cards"
    />
    <Callout
      v-if="quizData.hiw"
      :content="quizData.hiw"
    />
    <Callout
      v-if="quizData.above_quiz_callout"
      id="quiz-start"
      :content="quizData.above_quiz_callout"
    />
    <Callout
      v-if="quizData.pre_footer"
      :content="quizData.pre_footer"
    />
  </section>
</template>

<script>
import EmailSignupForm from '../components/EmailSignupForm.vue'
import Callout from '../components/Callout.vue'
import Details from '../components/Details.vue'
import DetailsCustomFlex from '../components/DetailsCustomFlex.vue'
import DetailsProducts from '../components/DetailsProducts.vue'
import WhyAbodu from '../components/WhyAbodu.vue'
import LongForm from '../components/LongForm.vue'
import Reviews from '../components/Reviews.vue'
import Press from '../components/Press.vue'

export default {
  components: {
    EmailSignupForm,
    Callout,
    Details,
    DetailsCustomFlex,
    DetailsProducts,
    WhyAbodu,
    LongForm,
    Reviews,
    Press,
  },
  data() {
    return { quizData: this.$quizData }
  },
}
</script>
