<template>
  <div
    v-if="content.list_items"
    class="list-block"
  >
    <h4
      v-if="content.list_title"
      class="list-title"
      v-html="content.list_title"
    />
    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{ 'icon-list-item': hasIcon || !!item.icon_image }"
      >
        <figure
          v-if="hasIcon || item.icon_image"
          class="list-icon"
        >
          <img
            :src="item.icon_image || defaultIcon"
            alt="List Icon"
          >
        </figure>
        <h4
          v-if="item.title"
          class="list-title"
          v-html="item.title"
        />
        <span
          class="list-text"
          v-html="item.text || item"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      items: this.content.list_items,
      icon: this.content.list_icon_image,
      hasIcon: !!this.content.list_icon_image,
    }
  },
  computed: {
    defaultIcon() {
      if (this.hasIcon) return this.icon
      return ''
    },
  },
}
</script>
