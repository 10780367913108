<template>
  <div
    v-if="title || text"
    class="title-block"
  >
    <h6
      v-if="kicker"
      class="kicker"
      v-html="kicker"
    />
    <h2 v-if="title_caps">
      <span v-html="title" />
      <!-- Can reference their home page - each span given an animation -->
      <span class="title-cap-wrapper">
        <span
          v-for="(titleCap, idx) in title_caps"
          :key="`${titleCap}-${idx}-${currentTitleIdx}`"
          class="title-cap"
          :class="{ 'animating': animating, 'leaving': idx !== currentTitleIdx }"
          v-html="titleCap"
        />
      </span>
    </h2>
    <h2
      v-else-if="title"
      v-html="title"
    />
    <h3
      v-if="subtitle"
      v-html="subtitle"
    />
    <p
      v-if="text"
      class="lead"
      v-html="text"
    />
    <small
      v-if="fine_print"
      class="fine-print"
      v-html="fine_print"
    />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const {
      kicker,
      title,
      title_caps,
      subtitle,
      text,
      fine_print,
    } = this.content

    return {
      kicker,
      title,
      title_caps,
      subtitle,
      text,
      fine_print,
      animating: false,
      currentTitleIdx: 0,
    }
  },
  mounted() {
    if (this.title_caps) this.incrementCurrentTitleIdx()
  },
  methods: {
    incrementCurrentTitleIdx() {
      this.animateTitleCap()
      setInterval(() => {
        this.animating = false
        this.currentTitleIdx = (this.currentTitleIdx + 1) % this.title_caps.length
        this.animateTitleCap()
      }, 3000)
    },
    animateTitleCap() {
      setTimeout(() => {
        this.animating = true
      }, 2500)
    },
  },
}
</script>

<style>
.title-cap-wrapper {
  position: relative;
  display: block;
}
.title-cap {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  text-decoration: underline;
  text-underline-offset: 2px;
  animation: slide-in 0.5s linear;
}
.animating {
  animation: slide-out 0.75s linear;
}
.leaving {
  display: none;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
</style>
