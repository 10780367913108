<template>
  <section
    v-if="isSubmitting"
    class="loading-gif"
  >
    <div class="loading-gif">
      <img
        :src="LoadingGif"
        alt="loading"
      >
    </div>
  </section>
  <section
    v-else
    id="contact-form"
    class="contact-form page-section multistep-form"
    :class="content.classes"
  >
    <h3
      id="get-in-touch"
      class="get-in-touch"
    >
      Get in Touch
    </h3>
    <!-- eslint-disable -->
    <div class="container">
      <TitleBlock :content="content" />
      <form id="form-start">
        <div class="form-content" id="multistep-top">
          <transition name="fade" mode="out-in">
            <div class='form-step form-step-1' key='multi-1' v-if="formStep===1">
              <h2>YOUR NAME</h2>
              <div class='inputs-step-wrapper'>
                <fieldset :class="{ 'has-error': errors.firstName }">
                <label for="first_name">First name</label>
                <input
                  id="first_name"
                  v-model="formData.firstName"
                  name="first_name"
                  type="text"
                  size="20"
                  @blur="handleBlur($event, 'firstName')"
                />
                <transition name="error-slide-in">
                  <p v-show="errors.firstName" class="error-msg">
                    Please enter your first name.
                  </p>
                </transition>
                </fieldset>
                <fieldset :class="{ 'has-error': errors.lastName }">
                  <label for="last_name">Last name</label>
                  <input
                    id="last_name"
                    v-model="formData.lastName"
                    name="last_name"
                    type="text"
                    size="20"
                    @blur="handleBlur($event, 'lastName')"
                  />
                  <transition name="error-slide-in">
                    <p v-if="errors.lastName" class="error-msg">
                      Please enter your last name.
                    </p>
                  </transition>
                </fieldset>
              </div>
              <button  
                class="btn"
                type="button"
                :disabled="isDisabled || null"
                @click="nextStep"
                v-html="content.cta_text || 'Continue'"
              />    
            </div>
       
            <div class='form-step form-step-2' key='multi-2' v-if="formStep===2">
              <h2>CONTACT INFORMATION</h2>
              <div class='inputs-step-wrapper'>
                <fieldset :class="{ 'has-error': errors.email }">
                  <transition name="tooltip-transition">
                    <div class="tooltip tooltip-0" v-if="showTooltip.email" ref="tooltipEmail">
                      <div class='tooltip-container'>
                        <h3>Why my email?</h3>
                        <p>This information is used in various ways, including scheduling tours, and providing product information and services.</p>
                        <div @click="showTooltip.email = !showTooltip.email" class='close-x'>X</div>
                      </div>
                    </div>
                  </transition>
                  <label for="email">Email <img @click="showTooltip.email = !showTooltip.email" :src='Tooltip' alt='Tooltip icon' ref="tooltipIcon1"/></label>
                  <input
                    id="email"
                    v-model="formData.email"
                    name="email"
                    type="email"
                    size="20"
                  />
                  <transition name="error-slide-in">
                    <p v-if="errors.email" class="error-msg">
                      Please provide a valid email.
                    </p>
                  </transition>
                </fieldset>
                <fieldset :class="{ 'has-error': errors.phone }">
                  <transition name="tooltip-transition">
                    <div class="tooltip tooltip-1" v-if="showTooltip.phone" ref="tooltipPhone">
                      <div class='tooltip-container'>
                        <h3>Why my phone number?</h3>
                        <p>We'll reach out to you to schedule a showroom visit or to connect about a feasibility study of your property.</p>
                        <div @click="showTooltip.phone = !showTooltip.phone" class='close-x'>X</div>
                      </div>
                    </div>
                  </transition>
                  <label for="phone">Phone number <img @click="showTooltip.phone = !showTooltip.phone" :src='Tooltip' alt='Tooltip icon' ref="tooltipIcon2"/></label>
                  <the-mask
                    id="phone"
                    v-model="formData.phone"
                    name="phone"
                    autocomplete="off"
                    mask="(###) ###-####"
                    :masked="false"
                    pattern="^\(?([2-9][0-8][0-9])\)?[\s]?([2-9][0-9]{2})[\-]?([0-9]{4})$"
                    title="(123) 456-7890"
                    type="tel"
                    size="20"
                  />
                  <transition name="error-slide-in">
                    <p v-if="errors.phone" class="error-msg">
                      Please enter a valid phone number.
                    </p>
                  </transition>
                </fieldset>
                <fieldset :class="{ 'has-error': errors.address || isAddressError }">
                  <transition name="tooltip-transition">
                    <div class="tooltip tooltip-2" v-if="showTooltip.address" ref="tooltipAddress">
                      <div class='tooltip-container'>
                        <h3>Why my address?</h3>
                        <p>We use this to determine your backyard size and if Abodu is feasible in your location.</p>
                        <div @click="showTooltip.address = !showTooltip.address" class='close-x'>X</div>
                      </div>
                    </div>
                  </transition>
                  <label @click="showTooltip.address = !showTooltip.address" for="address">Project Address <img :src='Tooltip' alt='Tooltip icon' ref="tooltipIcon3"/></label>
                  <input
                    id="address"
                    v-model="formData.address"
                    name="address"
                    type="search"
                    autocomplete="off"
                    @blur="handleBlur($event, 'address')"
                  />
                  <transition name="error-slide-in">
                    <p
                      v-if="errors.address || isAddressError"
                      class="error-msg"
                      :class="{ 'error-red': isAddressError }"
                    >
                      {{
                        $v.formData.address.required
                          ? 'Please enter a valid, full address.'
                          : 'Please enter your project address.'
                      }}
                    </p>
                  </transition>
                </fieldset>
              </div>
              <div class='btn-wrapper'>
                <button
                  class="btn back-btn"
                  type="button"
                  @click="formStep--"
                  v-html="'Back'"
                />
                <button  
                  class="continue-btn btn"
                  type="button"
                  :disabled="isDisabled || null"
                  @click="nextStep"
                  v-html="content.cta_text || 'Continue'"
                />
              </div>
            </div>
  
            <div class='form-step form-step-3' key='multi-3' v-if="formStep===3">
              <fieldset
                class="found-by-field"
                :class="{ 'has-error': errors.findOut }"
              >
                <input
                  id="found-by"
                  :value="formattedfindOut"
                  name="00N4W00000BNdsQ"
                  hidden
                />
                <label ref="findOutHeader" class="found-by-header" for="found-by"
                  >HOW DID YOU HEAR ABOUT US?</label
                >
                <div class="content-wrapper">
                  <p class="select-text">(select all that apply)</p>
                  <transition name="error-slide-in">
                    <p v-if="errors.findOut" class="error-msg">
                      Please select how you heard about Abodu.
                    </p>
                  </transition>
                  <div class="options-container">
                    <div
                      v-for="(option, idx) in content.found_by_options"
                      :key="idx"
                      class="option"
                    >
                      <input
                        :id="option"
                        v-model="formData.findOut"
                        :value="option"
                        type="checkbox"
                        :disabled="isSubmitting"
                        hidden
                      />
                      <label :for="option">{{ option }}</label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class='btn-wrapper'>
                <button
                  class="btn back-btn"
                  type="button"
                  @click="formStep--"
                  v-html="'Back'"
                />
                <button  
                  class="continue-btn btn"
                  type="button"
                  :disabled="isDisabled || null"
                  @click="nextStep"
                  v-html="content.cta_text || 'Continue'"
                />
              </div>
            </div>

            <div class='form-step form-step-4' key='multi-4' v-if="formStep===4">
              <h2>CONNECT WITH OUR TEAM</h2>
              <fieldset class="comments-field">
                <label for="comments">Comments</label>
                <textarea
                  id="comments"
                  v-model="formData.comments"
                  class="form-input"
                  name="00N4W00000BMj84"
                  aria-required="false"
                  type="text"
                  placeholder="Questions? We're here to help!"
                />
              </fieldset>
              <div class="form-submit">
                <button
                  class="btn back-btn"
                  type="button"
                  @click="formStep--"
                  v-html="'Back'"
                />
                <button
                  class="btn continue-btn"
                  :class="{ disabled: isDisabled }"
                  type="button"
                  :disabled="isDisabled || null"
                  @click.prevent="handleSubmit"
                  v-html="content.cta_text || 'Get in touch'"
                />
                <small
                  v-if="content.cta_fine_print"
                  class="cta-fine-print"
                  v-html="content.cta_fine_print"
                />
                <p v-if="formSubmitError" class="form-error">
                  An error occurred, please try submitting again.
                </p>
              </div>
            </div>
          </transition>
        </div>

        <transition name="error-slide-in">
          <p v-if="isQuizIncompleteError" class="error-msg is-general">
            Please answer the questions above the form.
          </p>
        </transition>
      </form>
    </div>
  </section>
</template>

<script>
import {
  required,
  email as emailValidation,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import { TitleBlock } from '../shared'
import { trackSubmission } from '../../helpers/tracking'
import LoadingGif from '../../images/loader.gif'
import Tooltip from '../../images/tooltip-icon.svg'

export default {
  components: {
    TitleBlock,
  },
  data() {
    return {
      content: this.$quizData.contact_form || {},
      formStep: 1,
      addressEntered: false,
      addressInit: false,
      autocompleteInitialized: false,
      formData: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        address: null,
        zip: null,
        comments: null,
        findOut: [],
      },
      errors: {
        email: false,
        firstName: false,
        lastName: false,
        phone: false,
        address: false,
        zip: false,
        findOut: false,
      },
      showTooltip: {
        email: false,
        phone: false,
        address: false,
      },
      Tooltip,
      LoadingGif,
      resultData: {},
      isQuizIncompleteError: false,
      isSubmitting: false,
      queryString: new URLSearchParams(window.location.search),
      referrerUrl: window.location.href,
      userAgent: navigator.userAgent,
      ipAddress: '',
      autocomplete: null,
      formSubmitError: false,
    }
  },
  validations: {
    formData: {
      email: { emailValidation, required, maxLength: maxLength(80) },
      firstName: { required, maxLength: maxLength(40) },
      lastName: { required, maxLength: maxLength(80) },
      phone: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      address: {
        required,
        isValidAddress() {
          if (this.addressEntered) {
            return true
          }
          if (!this.autocomplete || !this.$v.formData.address.required) {
            return true
          }
          const place = this.autocomplete.getPlace()
          if (place) {
            const addressNumber = place.address_components.filter(
              item => item.types[0] === 'street_number',
            )[0]
            if (addressNumber === undefined) return false
            return (
              this.formData.address
              === place.formatted_address.replace(/ \d{5}/, '')
            )
          }
          return false
        },
      },
      zip: {
        maxLength: maxLength(5),
        minLength: minLength(5),
        required,
      },
      findOut: {
        isAnswered() {
          const hasSelections = this.formData.findOut.length > 0
          if (hasSelections) {
            return true
          }
          if (
            this.$store.getters.SCREEN_WIDTH < 768
            && !this.$refs.findOutHeader.classList.contains('active')
          ) {
            this.$refs.findOutHeader.click()
          }
          return false
        },
      },
    },
  },
  computed: {
    getInputStep() {
      return this.formStep
    },
    quizResults() {
      return this.$store.getters.RESULTS
    },
    isAddressError() {
      return (
        !this.$v.formData.address.isValidAddress
        && this.$v.formData.address.required
      )
    },
    isDisabled() {
      return (
        this.isSubmitting
      )
    },
    incomingParams() {
      return {
        utm_campaign: this.getParamValue('utm_campaign'),
        utm_source: this.getParamValue('utm_source'),
        utm_medium: this.getParamValue('utm_medium'),
        utm_content: this.getParamValue('utm_content'),
        utm_term: this.getParamValue('utm_term'),
        gclid: this.getParamValue('gclid'),
        fbclid: this.getParamValue('fbclid'),
      }
    },
    isDesktop() {
      return this.$store.getters.SCREEN_WIDTH > 1280
    },
    isMobile() {
      return this.$store.getters.SCREEN_WIDTH < 768
    },
    formattedfindOut() {
      // sort array and return comma-joined string
      return [...this.formData.findOut].sort().join(',')
    },

    redirectLink() {
      if (this.content.redirect_link) {
        return this.content.pass_zip_param
          ? `${this.content.redirect_link}?zip=${this.resultData.zip}`
          : this.content.redirect_link
      }

      return 'http://abodu.com/thank-you'
    },
  },
  watch: {
    formStep(newFormStep, oldFormStep) {
      if (newFormStep === 2) {
        document.addEventListener('click', this.handleOutsideClick)
        setTimeout(async () => {
          await this.initAutoComplete()
        }, 600)
      }
      if (newFormStep === 3) {
        this.addressEntered = true
      }
      if (newFormStep !== 2) {
        document.removeEventListener('click', this.handleOutsideClick)
      }
      if (newFormStep !== oldFormStep) {
        this.scrollStep()
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick)
  },
  mounted() {
    this.$scrollTo('#contact-form', 500, {
      offset: this.isDesktop ? -5 : -20,
    })

    this.initializeAccordion()
  },

  methods: {
    scrollStep() {
      this.$scrollTo('#get-in-touch')
    },
    handleOutsideClick(event) {
      event.stopPropagation()
      event.preventDefault()
      const anyTooltipOpen = Object.values(this.showTooltip).some(value => value)

      const { tooltipIcon1 } = this.$refs
      const { tooltipIcon2 } = this.$refs
      const { tooltipIcon3 } = this.$refs

      if (anyTooltipOpen
        && !tooltipIcon1.contains(event.target)
        && !tooltipIcon2.contains(event.target)
        && !tooltipIcon3.contains(event.target)
      ) {
        this.showTooltip.address = false
        this.showTooltip.phone = false
        this.showTooltip.email = false
      }
    },
    toggleTooltip(field) {
      this.showTooltip[field] = !this.showTooltip[field]
    },
    snakeToCamel(str) {
      return str.replace(/([-_][a-z])/ig, $1 => $1.toUpperCase()
        .replace('-', '')
        .replace('_', ''))
    },
    async handleSubmit() {
      if (!this.noQuiz) {
        this.validateQuizAnswers()
      }

      this.formSubmitError = false
      if (this.validateForm() && !this.isQuizIncompleteError) {
        this.isSubmitting = true
        this.getResultData()

        window.sessionStorage.setItem('abodu_zip', this.formData.zip)

        try {
          await this.getIPAddressFromCloudflare()
          await this.sendDataToFormCollector()
        } catch (error) {
          window.console.error(`Error: ${error}`)
        } finally {
          await new Promise(resolve => resolve(trackSubmission(this.formData)))

          // Form submit pixel events
          if (typeof ndp !== 'undefined') {
            if (window.location.href.includes('_stg')) console.log('NDP FIRED')
            ndp('track', 'CONVERSION')
          }

          if (typeof window.report_conversion !== 'undefined') {
            window.report_conversion()
          }

          if (typeof _qevents !== 'undefined' && window.location.pathname.includes('/quiz/707')) {
            window._qevents.push({
              qacct: 'p-N8gRm6Zvw1gWS',
              labels: 'submit',
              event: 'submit',
            })
          }

          this.submitToAboduEndpoint()
        }
      }
      return false
    },
    async sendDataToFormCollector() {
      const spreadData = this.noQuiz ? this.formData : this.resultData
      const fetchUrl = this.noQuiz ? '-no-quiz' : ''
      const data = await fetch(
        `https://collector.thesistestingapps.com/insert/apps/abodu/forms/quiz-3g${fetchUrl}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...spreadData,
            campaign: this.incomingParams.campaign,
            source: this.incomingParams.source,
            medium: this.incomingParams.medium,
            content: this.incomingParams.content,
            term: this.incomingParams.term,
            gclid: this.incomingParams.gclid,
            timestamp: this.getTimestamp(),
          }),
        },
      )
      const res = await data.json()
      return res
    },
    async submitToAboduEndpoint() {
      const spreadData = this.noQuiz ? this.formData : this.resultData
      const data = await fetch('https://www.abodu.com/api/qualification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...spreadData,
          utm_campaign: this.incomingParams.utm_campaign,
          utm_source: this.incomingParams.utm_source,
          utm_medium: this.incomingParams.utm_medium,
          utm_content: this.incomingParams.utm_content,
          utm_term: this.incomingParams.utm_term,
          gclid: this.incomingParams.gclid,
          fbclid: this.incomingParams.fbclid,
        }),
      })

      if (data.status === 200) {
        window.location.href = this.redirectLink
      } else {
        this.formSubmitError = true
        this.isSubmitting = false
        fetch('https://formspree.io/f/xknazgzk', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            message: 'Abodu form submission error',
            submission: {
              ...spreadData,
              utm_campaign: this.incomingParams.utm_campaign,
              utm_source: this.incomingParams.utm_source,
              utm_medium: this.incomingParams.utm_medium,
              utm_content: this.incomingParams.utm_content,
              utm_term: this.incomingParams.utm_term,
              gclid: this.incomingParams.gclid,
              fbclid: this.incomingParams.fbclid,
            },
          }),
        })
      }
    },
    async getIPAddressFromCloudflare() {
      // Reference: https://ipdata.co/blog/how-to-get-the-ip-address-in-javascript/
      const res = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
      const data = await res.text()

      const ipAddress = data.split('\n')[2].split('ip=')[1]

      if (ipAddress) this.ipAddress = ipAddress
    },
    validateForm(step) {
      let currentFormInputs
      let anyErrors
      let keys
      let touchedFields
      if (this.formStep !== 3) {
        currentFormInputs = Array.from(document.querySelector('.multistep-form').querySelectorAll('input')).map(input => input.id)
        keys = currentFormInputs.map(input => this.snakeToCamel(input))
        touchedFields = keys.reduce((obj, field) => ({ ...obj, [field]: true }), {})
        this.$v.$touch(touchedFields)
        this.clearErrors(keys)
        anyErrors = keys.map(key => this.$v.formData[key].$anyError)
      } else {
        currentFormInputs = 'findOut'
        this.$v.$touch(touchedFields)
        this.clearErrors([currentFormInputs])
      }
      const { formData } = this

      const errorsBool = this.formStep !== 3 ? anyErrors.includes(true) : this.$v.formData.findOut.$anyError

      if (errorsBool) {
        // eslint-disable-next-line no-restricted-syntax
        if (this.formStep !== 3) {
          keys.forEach(key => {
            if (
              this.$v.formData[key]
              && this.$v.formData[key].$anyError
            ) {
              this.errors[key] = true
            } else {
              this.errors[key] = false
            }
          })
          return false
        }
        if (this.$v.formData.findOut.$anyError) {
          this.errors.findOut = true
        } else {
          this.errors.findOut = false
        }
        return !this.$v.formData.findOut.$anyError
      }
      return true
    },
    clearErrors(keys) {
      keys.forEach(key => {
        const newKey = this.snakeToCamel(key)
        this.errors[newKey] = false
      })
    },
    padZero(shorty) {
      return shorty.toString().padStart(2, '0')
    },
    getParamValue(key, defaultValue = '') {
      return this.queryString.get(key) || defaultValue
    },
    getTimestamp() {
      const rightNow = new Date()
      const monthNow = this.padZero(rightNow.getMonth() + 1)
      const dateNow = this.padZero(rightNow.getDate())
      const hourNow = this.padZero(rightNow.getHours())
      const minuteNow = this.padZero(rightNow.getMinutes())
      const secondNow = this.padZero(rightNow.getSeconds())
      return `${rightNow.getFullYear()}-${monthNow}-${dateNow} ${hourNow}:${minuteNow}:${secondNow}`
    },
    getResultData() {
      // Get answer for each question
      const questionData = this.quizResults.map(result => {
        if (
          result.label.includes(
            'When it comes to Accessory Dwelling Units (ADUs)',
          )
        ) {
          this.resultData.selectedAboutAdu = result.content
            .split('-')[0]
            .trim()
        } else if (result.label.includes('You are a:')) {
          if (result.content.includes('Homeowner')) {
            this.resultData.customerType = 'a Homeowner'
          } else {
            this.resultData.customerType = result.content
          }
        } else if (
          result.label.includes(
            'which of the following would you like to learn more',
          )
        ) {
          this.resultData.interestedInFinancing = !!result.content.find(i => i.selection.includes('Financing'))
        } else if (
          result.label.includes('When would you like to break ground on your')
        ) {
          this.resultData.selectedUrgency = result.content
        }
      })
      this.resultData.leadSource = 'Quiz Form'

      this.resultData = {
        ...this.formData,
        ...this.resultData,
      }
    },
    validateQuizAnswers() {
      const results = this.quizResults.map(i => i.content.toString())
      this.isQuizIncompleteError = !!results.includes('')
    },
    initAutoComplete() {
      const addressField = document.getElementById('address')
      // eslint-disable-next-line no-undef

      this.autocomplete = new google.maps.places.Autocomplete(addressField, {
        componentRestrictions: { country: ['us'] },
        fields: ['formatted_address', 'address_components'],
        types: ['address'],
      })

      this.autocomplete.addListener(
        'place_changed',
        this.populateAutocompleteField,
      )
    },
    populateAutocompleteField() {
      const place = this.autocomplete.getPlace()
      const postalCode = place.address_components.filter(
        item => item.types[0] === 'postal_code',
      )[0]

      if (postalCode) this.formData.zip = `${postalCode.long_name}`
      this.formData.address = place.formatted_address.replace(/ \d{5}/, '')
    },
    handleBlur(e, field) {
      if (this.formData[field] !== e.target.value) {
        this.formData[field] = e.target.value
      }
    },
    initializeAccordion() {
      const that = this

      $('.found-by-header').on('click', function () {
        if (that.$store.getters.SCREEN_WIDTH >= 768) return

        const header = $(this)

        header
          .siblings('.active')
          .next()
          .slideToggle(400, function () {
            $(this).prev().toggleClass('active', $(this).is(':visible'))
          })

        if (header.hasClass('active')) {
          header.next().slideToggle(400, function () {
            header.toggleClass('active', $(this).is(':visible'))
          })
        } else {
          header.toggleClass('active').next().slideToggle()
        }
      })
    },
    nextStep() {
      if (this.validateForm()) {
        this.formStep += 1
      }
    },
  },
}
</script>

<style scoped>
  .error-red {
    color: red;
  };

  .btn.disabled {
    background: #c4c4c4;
    cursor: not-allowed;
    pointer-events: none;
  };

  .contact-form h2 {
    text-align: left;
  };
</style>
