import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    screenWidth: window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth,
    pricing: null,
  },
  getters: {
    SCREEN_WIDTH: state => state.screenWidth,
    PRICING: state => state.pricing,
  },
  mutations: {
    MUTATE_SCREEN_WIDTH: (state, newWidth) => {
      // eslint-disable-next-line no-param-reassign
      state.screenWidth = newWidth
    },
    MUTATE_PRICING: (state, pricing) => {
      // eslint-disable-next-line no-param-reassign
      state.pricing = pricing
    },
  },
})

export default store
