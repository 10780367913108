// eslint-disable-next-line import/prefer-default-export
export const trackEvent = (action, category, label = null) => {
  const eventAction = action || 'cta-click'
  const eventCategory = category || 'thesis-page-interaction'

  if (typeof dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'gaEventTrigger',
      gaEventCategory: eventCategory,
      gaEventOrigin: window.location.pathname,
      gaEventAction: eventAction,
      gaEventLabel: label || eventAction,
    })
  }
}

export const trackSubmission = data => {
  const eventAction = 'SubmitApplication'
  const eventCategory = 'thesis-page-interaction'

  if (typeof dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'gaEventTrigger',
      gaEventCategory: eventCategory,
      gaEventOrigin: window.location.pathname,
      gaEventAction: eventAction,
      gaEventLabel: eventAction,
      user_email: data.email,
      user_phone_number: data.phone || '',
      user_zip: data.zipcode || '',
      user_fn: data.firstName,
      user_ln: data.lastName,
      user_address: data.street,
    })
  }
}
