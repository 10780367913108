<template>
  <div
    id="app"
    :class="{ 'insta-quiz-intro': isMultiSelectGrid }"
  >
    <ThankYou v-if="isThankYouPage" />
    <template v-else-if="isOverviewPage">
      <StickyQuizTop />
      <QuizIntro />
      <Footer />
    </template>
    <ThesisForm v-else>
      <template #headerSlot>
        <!-- Above quiz section -->
        <StickyQuizTop />
        <QuizIntro />
      </template>
      <template #loaderView>
        <!-- Multi-page embed post-questions -->
        <MultistepContactForm
          v-if="isMultistepContact"
          :noQuiz="isNoQuiz"
        />
        <ContactFormTooltip
          v-if="isContactTooltip"
          :noQuiz="isNoQuiz"
        />
        <ContactForm 
          v-if="!isMultistepContact && !isContactTooltip"
        />
      </template>
      <template #footerSlot>
        <!-- Below quiz section -->
        <QuizBottom />
        <!-- Moves quiz controls for MultiSelectGrid styling -->
        <ControlsContainer v-if="isMultiSelectGrid" />
        <Footer />
      </template>
    </ThesisForm>
  </div>
</template>

<script>
import StickyQuizTop from './sections/StickyQuizTop.vue'
import QuizIntro from './sections/QuizIntro.vue'
import ContactForm from './components/ContactForm.vue'
import ContactFormTooltip from './components/ContactFormTooltip.vue'
import QuizBottom from './sections/QuizBottom.vue'
import ControlsContainer from './components/ControlsContainer.vue'
import Footer from './components/Footer.vue'
import MultistepContactForm from './components/MultistepContact/MultistepContactForm.vue'

const ThankYou = () => import('./sections/ThankYou.vue')

export default {
  components: {
    StickyQuizTop,
    QuizIntro,
    ContactForm,
    ContactFormTooltip,
    MultistepContactForm,
    QuizBottom,
    ControlsContainer,
    Footer,
    ThankYou,
  },
  data() {
    return {
      isThankYouPage: this.$quizData.is_thank_you_page,
      isOverviewPage: this.$quizData.is_overview_page,
      currentBranch: null,
    }
  },
  computed: {
    isQuizStarted() {
      return this.$store.getters.QUIZ_STARTED
    },
    currentStep() {
      return this.$store.getters.ADVANCE_QUIZ_DATA.currentStep - 1
    },
    isMobile() {
      return this.$store.getters.SCREEN_WIDTH < 768
    },
    isMultiSelectGrid() {
      return this.$quizData.is_insta_quiz && this.$store.getters.ADVANCE_QUIZ_DATA.currentStep === 0
    },
    isMultistepContact() {
      return this.$store.getters.QUIZDATA.is_multistep_contact
    },
    isContactTooltip() {
      return this.$store.getters.QUIZDATA.is_contact_form_tooltip
    },
    isNoQuiz() {
      return this.$store.getters.QUIZDATA.no_quiz
    },
  },
  watch: {
    currentStep() {
      if (this.isQuizStarted && this.isMobile) {
        const questionData = this.$quizData.sequences[this.currentStep].questions[0]

        if (questionData.type === 'multiselect') {
          this.$scrollTo('#quiz-start', 500, {
            offset: -100,
          })
        }
      }
    },
  },
  created() {
    this.$store.dispatch('INITIALIZE_STORE', this.$quizData)
    this.$store.dispatch('/results/INITIALIZE_RESULTS', this.$quizData.sequences)
    if (this.isNoQuiz) this.$store.dispatch('QUIZ_COMPLETED', true)
  },
  mounted() {
    
    this.setScreenWidthOnResize()
  },
  methods: {
    setScreenWidthOnResize() {
      const that = this
      window.addEventListener('resize', () => {
        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth

        that.$store.commit('MUTATE_SCREEN_WIDTH', width)
      })
    },
  },
}
</script>
