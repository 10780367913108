<template>
  <section
    class="why-abodu"
    :class="content.classes"
  >
    <div class="container">
      <MediaRotator
        v-if="content.images"
        :content="content"
      />
      <MediaBlock
        v-else
        :content="content"
      />
      <TitleBlock :content="content" />

      <div class="reasons-wrapper">
        <div class="left-panel">
          <p class="panel-header">
            Space for what matters
          </p>

          <div class="panel">
            <TitleBlock
              v-for="(c, i) in content.left_panel"
              :key="i"
              :content="c"
            />
          </div>
        </div>

        <div class="right-panel">
          <p class="panel-header">
            Rental Income
          </p>

          <div class="panel">
            <TitleBlock
              v-for="(c, i) in content.right_panel"
              :key="i"
              :content="c"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  MediaBlock,
  TitleBlock,
} from './shared'
import MediaRotator from './MediaRotator.vue'

export default {
  components: {
    MediaBlock,
    TitleBlock,
    MediaRotator,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
