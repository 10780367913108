<template>
  <div class="detail">
    <div class="detail-wrapper">
      <MediaBlock :content="content" />
      <h4
        v-if="content.title"
        class="detail-title"
        v-html="content.title"
      />
      <p
        v-if="content.text"
        class="detail-text"
        v-html="content.text"
      />
      <ListBlock :content="content" />

      <transition name="fade">
        <p
          v-if="dynamicPrice"
          class="dynamic-price"
        >
          <span v-html="dynamicPrice.price" />
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
import { MediaBlock, ListBlock } from './shared'

export default {
  components: {
    MediaBlock,
    ListBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hasMonthlyPricing: this.$quizData.has_monthly_pricing,
    }
  },
  computed: {
    dynamicPrice() {
      if (this.$store.getters.PRICING) {
        const homeType = this.getHomeType()
        return this.$store.getters.PRICING[homeType]
      }

      return null
    },
  },
  mounted() {
    if (this.content.carousel) this.initializeCarousel()
  },
  methods: {
    getHomeType() {
      switch (this.content.title) {
        case 'Abodu Studio':
          return 'Studio'
        case 'Abodu One':
          return 'A1'
        case 'Abodu Two':
          return 'A2'
        case 'Dwell House':
          return 'Dwell'
        default:
          return this.content.title
      }
    },
    initializeCarousel() {
      const { content } = this
      $('.details-content.is-carousel-on-mobile').each(function () {
        const slider = $(this)
        if ($(this).replaceWithSVG) {
          slider.bind('DOMNodeInserted', () => {
            slider.find('[src$=".svg"]').each(function () {
              $(this).replaceWithSVG()
            })
          })
        }
        slider.slick({
          mobileFirst: true,
          responsive: content.responsive_settings || [
            {
              breakpoint: 0,
              settings: {
                arrows: false,
                dots: true,
                slidesToScroll: 1,
                slidesToShow: 1,
              },
            },
          ],
        })
      })
    },
  },
}
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
