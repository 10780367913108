<template>
  <div
    ref="wrapper"
    class="mock-controls-wrapper"
    :class="{ 'hide-controls': hasIntro && !quizVisible }"
  />
</template>

<script>
export default {
  data() {
    return {
      hasIntro: !!this.$quizData.callout_hero,
      quizVisible: false,
      observer: null,
    }
  },
  mounted() {
    const queryInterval = setInterval(() => {
      const controls = document.querySelector('.quiz-input .controls-container')

      if (controls) {
        this.$refs.wrapper.appendChild(controls)
        clearInterval(queryInterval)
      }
    }, 100)

    // If insta quiz also has intro
    if (this.$quizData.callout_hero) {
      this.initObserver()
    }
  },
  beforeDestroy() {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    initObserver() {
      if ('IntersectionObserver' in window) {
        this.observer = new IntersectionObserver(entries => {
          entries.forEach(({ isIntersecting }) => {
            this.quizVisible = isIntersecting
          })
        }, {
          root: null,
          threshold: 0.25,
        })

        this.observer.observe(document.getElementById('multi-page-start'))
      } else {
        this.quizVisible = true
      }
    },
  },
}
</script>
