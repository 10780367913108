<template>
  <section
    class="longform"
  >
    <div class="container">
      <TitleBlock :content="content" />


      <div class="longform-content">
        <ImageTextPair
          v-for="(itp, i) in content.image_text_pairs"
          :key="i"
          :content="itp"
        />
      </div>

      <CTABlock :content="content" />
    </div>
  </section>
</template>

<script>
import TitleBlock from './shared/TitleBlock.vue'
import ImageTextPair from './ImageTextPair.vue'
import CTABlock from './shared/CTABlock.vue'


export default {
  components: {
    TitleBlock,
    ImageTextPair,
    CTABlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hasMonthlyPricing: this.$quizData.has_monthly_pricing,
    }
  },
}
</script>
