<template>
  <figure
    v-if="content.image"
    class="media-block"
  >
    <picture>
      <source
        v-if="content.lg_image"
        :srcset="content.lg_image"
        media="(min-width: 1280px)"
      >
      <source
        v-if="content.md_image"
        :srcset="content.md_image"
        media="(min-width: 1024px)"
      >
      <source
        v-if="content.sm_image"
        :srcset="content.sm_image"
        media="(min-width: 768px)"
      >
      <img
        :src="content.image"
        :alt="content.image_alt || alt"
      >
    </picture>
    <figcaption
      v-if="content.figcaption"
      class="figcaption"
      v-html="content.figcaption"
    />

    <CTABlock
      v-if="content.absolute_cta"
      :content="content"
    />
  </figure>
</template>

<script>
import CTABlock from './CTABlock.vue'

export default {
  components: {
    CTABlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
    alt: {
      type: String,
      default: '',
    },
  },
}
</script>
