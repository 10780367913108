<template>
  <section class="email-signup-form">
    <div class="container">
      <MediaBlock
        :content="content"
      />

      <div
        class="content"
        :style="!isSubmitting ? 'display: block' : 'display: none'"
      >
        <TitleBlock
          :content="content"
        />

        <form
          id="email-form"
          ref="emailForm"
          class="signup-form"
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
        >
          <p class="form-kicker">
            Get in touch
          </p>

          <div class="form-content">
            <fieldset :class="{ 'has-error': errors.firstName }">
              <label for="first_name">First name</label>
              <input
                id="first_name"
                v-model="formData.firstName"
                name="first_name"
                type="text"
                size="20"
                @blur="handleBlur($event, 'firstName')"
              >
              <transition name="error-slide-in">
                <p
                  v-show="errors.firstName"
                  class="error-msg"
                >
                  Please enter your first name.
                </p>
              </transition>
            </fieldset>
            <fieldset :class="{ 'has-error': errors.lastName }">
              <label for="last_name">Last name</label>
              <input
                id="last_name"
                v-model="formData.lastName"
                name="last_name"
                type="text"
                size="20"
                @blur="handleBlur($event, 'lastName')"
              >
              <transition name="error-slide-in">
                <p
                  v-if="errors.lastName"
                  class="error-msg"
                >
                  Please enter your last name.
                </p>
              </transition>
            </fieldset>
            <fieldset :class="{ 'has-error': errors.email }">
              <label for="email">Email</label>
              <input
                id="email"
                v-model="formData.email"
                name="email"
                type="email"
                size="20"
              >
              <transition name="error-slide-in">
                <p
                  v-if="errors.email"
                  class="error-msg"
                >
                  Please provide a valid email.
                </p>
              </transition>
            </fieldset>
            <fieldset :class="{ 'has-error': errors.phone }">
              <label for="phone">Phone number</label>
              <the-mask
                id="phone"
                v-model="formData.phone"
                name="phone"
                autocomplete="off"
                mask="(###) ###-####"
                :masked="false"
                pattern="^\(?([2-9][0-8][0-9])\)?[\s]?([2-9][0-9]{2})[\-]?([0-9]{4})$"
                title="(123) 456-7890"
                type="tel"
                size="20"
              />
              <transition name="error-slide-in">
                <p
                  v-if="errors.phone"
                  class="error-msg"
                >
                  Please enter a valid phone number.
                </p>
              </transition>
            </fieldset>
            <fieldset :class="{ 'has-error': errors.street || isAddressError }">
              <label for="street">Project Address</label>
              <input
                id="street"
                v-model="formData.street"
                name="street"
                type="search"
                autocomplete="off"
                @blur="handleBlur($event, 'street')"
              >
              <transition name="error-slide-in">
                <p
                  v-if="errors.street || isAddressError"
                  class="error-msg"
                  :class="{ 'error-red': isAddressError }"
                >
                  {{ $v.formData.street.required ? 'Please enter a valid, full street address.'
                    : 'Please enter your project address.' }}
                </p>
              </transition>
            </fieldset>

            <input
              type="hidden"
              name="oid"
              value="00D4W000001VDUs"
            >
            <input
              type="hidden"
              name="retURL"
              value="https://get.abodu.com/email-thank-you"
            >

            <!-- Other Salesforce -->
            <input
              id="lead_source"
              maxlength="255"
              name="lead_source"
              size="20"
              type="hidden"
              value="Future Service Area"
            >
            <!-- Lead Type -->
            <input
              id="00N4W00000O5oaa"
              maxlength="255"
              name="00N4W00000O5oaa"
              size="20"
              type="hidden"
              value="Sales"
            >
            <!-- Referrer Url -->
            <input
              id="00N4W00000RMTYq"
              maxlength="255"
              name="00N4W00000RMTYq"
              size="20"
              type="hidden"
              :value="referrerUrl"
            >
            <!-- User Agent -->
            <input
              id="User_Agent__c"
              maxlength="255"
              name="User_Agent__c"
              size="20"
              type="hidden"
              :value="userAgent"
            >
            <!-- IP Address -->
            <input
              id="IPv6_Address__c"
              maxlength="200"
              name="IPv6_Address__c"
              size="20"
              type="hidden"
              :value="ipAddress"
            >

            <div class="form-submit">
              <button
                class="btn"
                :class="{ 'disabled': isDisabled }"
                type="button"
                :disabled="isDisabled || null"
                @click.prevent="handleSubmit"
                v-html="content.cta_text || 'Sign Up'"
              />
            </div>

            <p class="disclaimer">
              Drop us your info, and we’ll let you know as soon as we start serving your area.
              You can always learn more about our products at www.abodu.com. By providing your
              information, you consent to us contacting you about backyard home opportunities.
            </p>
          </div>
        </form>
      </div>
      <div
        class="content loading"
        :style="isSubmitting ? 'display: block' : 'display: none'"
      >
        <img
          class="loading-loop"
          :src="LoadingGif"
          alt="loading"
        >
      </div>
    </div>
  </section>
</template>

<script>
import {
  required,
  email as emailValidation,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import MediaBlock from './shared/MediaBlock.vue'
import TitleBlock from './shared/TitleBlock.vue'
import { trackSubmission } from '../helpers/tracking'
import LoadingGif from '../images/loader.gif'

export default {
  components: {
    MediaBlock,
    TitleBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitting: false,
      LoadingGif,
      resultData: {},
      autocomplete: null,
      formData: {
        email: null,
        firstName: null,
        lastName: null,
        street: null,
        phone: null,
      },
      errors: {
        email: false,
        firstName: false,
        lastName: false,
        street: false,
        phone: false,
      },
      thankYouData: {
        title: 'We received your information.',
        text: "You'll receive an email when Abodu's available in your area.",
      },
      referrerUrl: window.location.href,
      userAgent: navigator.userAgent,
      ipAddress: '',
    }
  },
  validations: {
    formData: {
      email: { emailValidation, required, maxLength: maxLength(80) },
      firstName: { required, maxLength: maxLength(40) },
      lastName: { required, maxLength: maxLength(80) },
      phone: {
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      street: {
        required,
        isValidAddress() {
          if (!this.autocomplete || !this.$v.formData.street.required) return true
          const place = this.autocomplete.getPlace()
          if (place) {
            const streetNumber = place.address_components.filter(item => item.types[0] === 'street_number')[0]
            if (streetNumber === undefined) return false
            return this.formData.street === place.formatted_address
          }
          return false
        },
      },
    },
  },
  computed: {
    isAddressError() {
      return !this.$v.formData.street.isValidAddress && this.$v.formData.street.required
    },
    isDisabled() {
      return this.isAddressError || this.isSubmitting || this.$v.$error || this.$v.$anyError
    },
    incomingParams() {
      return {
        campaign: this.getParamValue('utm_campaign'),
        source: this.getParamValue('utm_source'),
        medium: this.getParamValue('utm_medium'),
        content: this.getParamValue('utm_content'),
        term: this.getParamValue('utm_term'),
        gclid: this.getParamValue('gclid'),
        fbclid: this.getParamValue('fbclid'),
      }
    },
  },
  mounted() {
    if (typeof google !== 'undefined') {
      this.initAutoComplete()
    }
  },
  methods: {
    async handleSubmit() {
      if (this.validateForm()) {
        this.isSubmitting = true
        this.getResultData()

        try {
          await this.getIPAddressFromCloudflare()
          await this.sendDataToFormCollector()
        } catch (error) {
          window.console.error(`Error: ${error}`)
        } finally {
          await new Promise(resolve => resolve(trackSubmission(this.formData)))
          this.$refs.emailForm.submit()
        }
      }
      return false
    },
    async sendDataToFormCollector() {
      const data = await fetch('https://collector.thesistestingapps.com/insert/apps/abodu/forms/quiz-1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...this.resultData,
          campaign: this.incomingParams.campaign,
          source: this.incomingParams.source,
          medium: this.incomingParams.medium,
          content: this.incomingParams.content,
          term: this.incomingParams.term,
          gclid: this.incomingParams.gclid,
          timestamp: this.getTimestamp(),
        }),
      })
      const res = await data.json()
      return res
    },
    async getIPAddressFromCloudflare() {
      const res = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
      const data = await res.text()
      const ipAddress = data.split('\n')[2].split('ip=')[1]
      if (ipAddress) this.ipAddress = ipAddress
    },
    getTimestamp() {
      const rightNow = new Date()
      const monthNow = this.padZero(rightNow.getMonth() + 1)
      const dateNow = this.padZero(rightNow.getDate())
      const hourNow = this.padZero(rightNow.getHours())
      const minuteNow = this.padZero(rightNow.getMinutes())
      const secondNow = this.padZero(rightNow.getSeconds())
      return `${rightNow.getFullYear()}-${monthNow}-${dateNow} ${hourNow}:${minuteNow}:${secondNow}`
    },
    getResultData() {
      this.resultData = {
        ...this.formData,
      }
    },
    validateForm() {
      this.$v.$touch()
      const { formData } = this
      const keys = Object.keys(formData)
      this.clearErrors(keys)
      if (this.$v.$anyError) {
        // eslint-disable-next-line no-restricted-syntax
        keys.forEach(key => {
          if (this.$v.formData[key] && this.$v.formData[key].$anyError) {
            this.errors[key] = true
          }
        })
        return false
      }
      return true
    },
    clearErrors(keys) {
      keys.forEach(key => {
        this.errors[key] = false
      })
    },
    handleBlur(e, field) {
      if (this.formData[field] !== e.target.value) this.formData[field] = e.target.value
    },
    initAutoComplete() {
      const addressField = document.getElementById('street')
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(addressField, {
        componentRestrictions: { country: ['us'] },
        fields: ['formatted_address', 'address_components'],
        types: ['address'],
      })

      this.autocomplete.addListener('place_changed', this.populateAutocompleteField)
    },
    populateAutocompleteField() {
      const place = this.autocomplete.getPlace()
      const postalCode = place.address_components.filter(item => item.types[0] === 'postal_code')[0]

      this.formData.street = place.formatted_address
      if (postalCode) this.formData.zipcode = `${postalCode.long_name}`
    },
    getParamValue(key, defaultValue = '') {
      return this.queryString.get(key) || defaultValue
    },
  },
}
</script>
