<template>
  <div :id="content.id" class="image-text-pair">
    <h2
      v-if="content.show_mobile_title"
      class="mobile-title"
      v-html="content.title"
    />

    <MediaBlock :content="content" />

    <div class="image-text-content">
      <TitleBlock :content="content" />

      <ul v-if="content.list_items" class="image-text-list">
        <li v-for="(li, i) in content.list_items" :key="i" v-html="li" />
      </ul>

      <p v-if="content.pricing" class="pricing" v-html="content.pricing" />

      <transition name="fade">
        <p v-if="dynamicPrice" class="dynamic-price">
          <span class="big-price" v-html="dynamicPrice.price" />
          <template v-if="hasMonthlyPricing">
            or
            <span class="big-price" v-html="dynamicPrice.monthlyPrice" />
            /mo*
          </template>
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
  import MediaBlock from './shared/MediaBlock.vue';
  import TitleBlock from './shared/TitleBlock.vue';

  export default {
    components: {
      MediaBlock,
      TitleBlock,
    },
    props: {
      content: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        hasMonthlyPricing: this.$quizData.has_monthly_pricing,
      };
    },
    computed: {
      dynamicPrice() {
        if (this.$store.getters.PRICING) {
          let homeType = this.getHomeType();
          homeType = homeType === 'Dwell House' ? 'Dwell' : homeType;
          return this.$store.getters.PRICING[homeType];
        }

        return null;
      },
    },
    methods: {
      getHomeType() {
        switch (this.content.title) {
          case 'Abodu Studio':
            return 'Studio';
          case 'Abodu One':
            return 'A1';
          case 'Abodu Two':
            return 'A2';
          default:
            return this.content.title;
        }
      },
    },
  };
</script>

<style>
  .image-text-pair .title-block h2 {
    display: none;
  }

  @media screen and (min-width: 48rem) {
    .mobile-title {
      display: none;
    }

    .image-text-pair .title-block h2 {
      display: block;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
