<template>
  <section class="quiz-bottom">
    <Details
      v-if="quizData.quiz_banner"
      :content="quizData.quiz_banner"
    />
    <Callout
      v-if="quizData.hiw_bottom"
      :content="quizData.hiw_bottom"
    />
    <Callout
      v-if="quizData.product_callout"
      :content="quizData.product_callout"
    />
    <Details
      v-if="quizData.icon_details"
      :content="quizData.icon_details"
    />
    <Reviews
      v-if="quizData.reviews"
      :content="quizData.reviews"
    />
    <Press
      v-if="quizData.featured_press"
      :content="quizData.featured_press"
    />
    <Callout
      v-if="quizData.final_callout"
      :content="quizData.final_callout"
    />
    <Callout
      v-if="quizData.image_callout"
      :content="quizData.image_callout"
    />
    <Accordion
      v-if="quizData.faq"
      :content="quizData.faq"
    />
  </section>
</template>
<script>
import Callout from '../components/Callout.vue'
import Details from '../components/Details.vue'
import Reviews from '../components/Reviews.vue'
import Press from '../components/Press.vue'
import Accordion from '../components/Accordion.vue'

export default {
  components: {
    Callout,
    Details,
    Reviews,
    Press,
    Accordion,
  },
  data() {
    return {
      quizData: this.$quizData,
    }
  },
}
</script>
